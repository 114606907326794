<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-3">
				<b>Marca</b>
				<a-select :allowClear="true" show-search option-filter-prop="children" :filter-option="filterOption" :loading="brandsSpinnerLoader" :disabled="brandsSpinnerLoader" class="text-uppercase"
						  :style="{ width: '100%' }" @change="getModelsByBrand" v-model="payload.brand_id">
					<a-select-option v-for="(brand, index) in motorcycleBrandList" :key="index" :value="brand.id">{{ brand.name.toUpperCase() }}</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Modelo</b>
				<a-select :allowClear="true" show-search option-filter-prop="children" :filter-option="filterOption" :loading="modelSpinnerLoader" class="text-uppercase" :style="{ width: '100%' }"
						  v-model="payload.model_id" @change="getDesigns">
					<a-select-option v-for="(model, i) in motorcycleModelByBrandList" :key="i" :value="model.id"> {{ model.name }}</a-select-option>
				</a-select>
			</div>
			<div class="col-md-3"></div>
			<div class="col-md-3 text-right">
				<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Añadir</a-button>
				<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
			</div>
		</div>
		<div class="row pt10">
			<div class="col-md-12">
				<a-table :columns="columns" :dataSource="designsList" rowKey="id">
					<div slot="brandName" slot-scope="record">{{ record.brandName }}</div>
					<div slot="brandModel" slot-scope="record">{{ record.brandModel }}</div>
					<div slot="lastVersion" slot-scope="record">{{ record.lastVersion }}</div>
					<div slot="action" slot-scope="record">
						<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
						<a-button class="ml7" size="small" icon="minus-circle" @click="onDeleteRecord(record.id)" />
					</div>
				</a-table>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'Designs',
	data() {
		return {
			payload: {
				brand_id: '',
				model_id: '',
			},
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Marca',
					dataIndex: 'brandName',
					align: 'center',
					width: '20%',
				},
				{
					title: 'Modelo',
					dataIndex: 'modelName',
					align: 'center',
					width: '20%',
				},
				{
					title: 'Año',
					dataIndex: 'year',
					align: 'center',
					width: '20%',
				},
				{
					title: 'Última versión',
					scopedSlots: { customRender: 'lastVersion' },
					align: 'center',
					width: '20%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '10%',
				},
			],
		}
	},
	computed: {
		...mapGetters('designs', ['designsList', 'spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('motorcycleBrand', ['motorcycleBrandList']),
		...mapGetters('motorcycleModels', ['motorcycleModelByBrandList']),
		brandsSpinnerLoader() {
			return this.$store.state.motorcycleBrand.spinnerLoader
		},
		modelSpinnerLoader() {
			return this.$store.state.motorcycleModels.spinnerLoader
		},
	},
	beforeMount() {
		if (this.motorcycleBrandList.length == 0) {
			this.$store.dispatch('motorcycleBrand/GET_BRANDS')
		}
	},
	mounted() {
		if (this.designsList.length == 0) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			// Carga listado de diseños
			this.$store.dispatch('designs/GET')
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onAddRecord() {
			this.$router.push(`/disenios/new`)
		},
		onEditRecord(id) {
			console.log('onEditRecord-->', id);
		},
		onDeleteRecord(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el registro?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('designs/DELETE', id)
				}
			})
		},
		getModelsByBrand() {
			if (utilities.objectValidate(this.payload, 'brand_id', false)) {
				this.$store.dispatch('motorcycleModels/GET_MODELS_BY_BRAND', {
					brand_id: this.payload.brand_id,
				})
			}
		},
		getDesigns() {
			this.$store.dispatch('designs/GET_BY_MODEL', this.payload.model_id).then(response => {
				console.log('getDesigns-->', response)
			})
		},
	},
}
</script>