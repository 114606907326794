<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <a-tabs @change="onChangeTab" :activeKey="activeTab" :defaultActiveKey="activeTab">
                        <a-tab-pane v-for="element in actualSubmenus" :tab="element.title" :key="element.key" force-render>
                            <div v-if="element.key == 'repository_repository'">
                                <designsComponent />
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import utilities from '@/services/utilities'
//
import designsComponent from '@/components/designs'

export default {
    name: 'DesignsView',
    components: {
        designsComponent,
    },
    computed: {
        ...mapState(['settings']),
        actualSubmenus() {
            //
            if (utilities.objectValidate(this.settings, 'menu', false)) {
                let index = this.settings.menu.findIndex((e) => e.url == this.$route.path)
                if (utilities.objectValidate(this.settings, ['menu', index, 'submenus'], false)) {
                    return this.settings.menu[index].submenus
                }
                return []
            } else {
                return []
            }
        },
    },
    data() {
        return {
            activeTab: '',
        }
    },
    mounted() {
        this.onChangeTab(this.actualSubmenus[0].key)
    },
    methods: {
        onChangeTab(key) {
            let theMenu = this.actualSubmenus.find((e) => e.key == key)
            if (!this.isUserAdmin && utilities.objectValidate(theMenu, 'require_auth', false)) {
                this.requireAuthentication(theMenu.id)
            } else {
                this.activeTab = key
            }
        },
    },
}
</script>